import React, { useState, useRef, useEffect, useCallback } from "react";
import "./Filter.css";
import "./Jobs.scss";
import "./PreviewJob.scss";
import filterLogo from "../../assets/images/Group.png";
import circle from "../../assets/images/circle.png";
import money from "../../assets/images/money.png";
import calendar from "../../assets/images/calendar.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Layout/Spinner";
import { useLocation } from "react-router-dom";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { MdOutlineCheckCircle } from "react-icons/md";
import { State } from "country-state-city";
import PropagateLoader from "react-spinners/PropagateLoader";
import home from "../../assets/images/home.png";
import game from "../../assets/images/game.png";
import arrowup from "../../assets/images/Arrowup.jpg";
import { SlLocationPin } from "react-icons/sl";
import { BiBriefcaseAlt } from "react-icons/bi";
import { RxCounterClockwiseClock, RxCross1 } from "react-icons/rx";
import { FiTerminal, FiUsers } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { LuFilter } from "react-icons/lu";
import citiesData from "../Employer/citiesData.json";
import { IoSearch } from "react-icons/io5";
import "./Filter.css";
import Dropdown from "./Dropdown";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import { determineBgColor, determineColor, timeAgo } from "../../utils/helper";
import ProjectCategorySelector from "../EmployerNew/ProjectCategorySelector";
import ProjectSpecializationSelector from "../EmployerNew/ProjectSpecializationSelector";
import TruncateText from "../EmployerNew/TruncateText";
import { DurationDropDown, DurationTypeDropDown, FreelancerDropDown, MaximumBudgetForHourlyProject, MinimumBudgetForHourlyProject } from "../EmployerNew/DropDowns";
import ApplyButton from "./ApplyButton";
import PaginationWithTooltip from "./PaginationWithTooltip";
import { logEvent } from "../../utils/Analytics";
function Jobs() {
    const { filterType } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const { user } = useSelector((state) => state.user);

    // const selectedCategory = location.state?.category || "";
    const selectedCategory = sessionStorage.getItem("selectedCategory");
    const selectedCategoryFromTalentMarketPlace = sessionStorage.getItem("selectedCategoryTalentMarketPlace");

    const handleAddEvent = (str) => {
        logEvent("Button", `Clicked ${str} button`, user ? user.role : "Guest");
    };

    const openPreviewPageInNewTab = (state) => {
        const data = state.data;
        
        // Create sanitized versions of each component
        const sanitizedProfile = data.profile?.replace(/[^\w\s-]/g, "-").replace(/\s+/g, "-").replace(/-+/g, "-") || "";
        const sanitizedOpportunityType = data.opportunityType?.replace(/[^\w\s-]/g, "-").replace(/\s+/g, "-").replace(/-+/g, "-") || "";
        const sanitizedOrgName = data.employer?.organisationName?.replace(/[^\w\s-]/g, "-").replace(/\s+/g, "-").replace(/-+/g, "-") || "";
        
        // Construct URL with sanitized components
        const url = `/preview/${sanitizedProfile}-${sanitizedOpportunityType}-at-${sanitizedOrgName}_${data._id}`.toLowerCase();
        
        return url; // Return URL but do not open it automatically
    };
    const [profiledetail, setprofiledetail] = useState(null);
    const getResumeData = async () => {
        const { data } = await axios.get("/api/user/getResume", {
            headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
            },
        });
        setprofiledetail(data.resume);
    };

    const handleApplyNow = (state) => {
        const data = state.data;
        let url = "";
        const { phone, city, resume } = user;

        if (!phone || !city || !resume || !profiledetail || profiledetail.Education.length === 0 || (profiledetail.Job.length === 0 && profiledetail.Internship.length === 0) || profiledetail.Skills.length === 0 || profiledetail.Portfolio.length === 0 || profiledetail.DocDetails.length === 0) {
            return "/profile";
            // navigate("/profile");
        } else if ((phone || city) && !resume) {
            return "/profile";
            // navigate("/profile");
        } else {
            // navigate("/coverLetterPage", { state: data });
            const jobId = `${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
            const encodedJobId = encodeURIComponent(jobId);
            // navigate(`/coverletterpage/${encodedJobId}`, { state: data });
            if (data.opportunityType === "Fixed Jobs" && connectCredit < 8) {
                setShowModal(true);
            } else if (data.opportunityType === "Hourly" && connectCredit < 4) {
                setShowModal(true);
            } else {
                url = `/coverletterpage/${jobId} `;
                //navigate(`/coverletterpage/${encodedJobId} `,{state:data})
            }
        }

        // let url = `/coverletterpage/987werhwjhrjweghewr`;
        return url;
    };

    const selectedCountry = "India";

    const customStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: "#8b8b8b", // Custom color for the placeholder
            fontSize: "13px", // Custom font size
            fontWeight: "400", // Custom font weight for the placeholder
        }),
        control: (provided, state) => ({
            ...provided,
            border: "none",
            borderRadius: "3px",
            boxShadow: "none",
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            display: "none",
            borderLeft: "none",
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            paddingRight: 0, // Adjust padding to remove space between text and dropdown icon
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ECFFFF" : "white", // Change hover color here
            color: state.isFocused ? "#3498DB" : "black", // Change hover color here
            "&:hover": {
                backgroundColor: "#ECFFFF", // Change hover color here
                color: "#3498DB",
            },
        }),
    };

    const [heading, setHeading] = useState(false);

    const handleTitle = () => {
        setHeading(!heading);
    };
    const calculateDaysDifference = (startDate) => {
        const start = new Date(startDate);
        const end = new Date();

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = end - start;

        // Convert the difference to days
        const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

        // Set the state with the calculated difference
        return differenceInDays;
    };

    const [state, setState] = useState("");

    const formatNumberWithCommas = (number) => {
        // Convert number to string
        let strNumber = number.toString();

        // Split the string into parts (crores, lakhs, thousands, and hundreds)
        let crores = strNumber.slice(0, -7);
        let lakhs = strNumber.slice(-7, -5);
        let thousands = strNumber.slice(-5, -3);
        let hundreds = strNumber.slice(-3);

        // Join the parts with commas
        return crores + (crores ? "," : "") + lakhs + (lakhs ? "," : "") + thousands + "," + hundreds;
    };

    function valuetext(value) {
        return { value };
    }

    const numberOfFreelancerNeededData = [
        {
            value: "More than 4",
            label: "More than 4",
        },
        {
            value: "Upto 4",
            label: "Upto 4",
        },
    ];
    const durationData = [
        {
            value: 1,
            label: "1",
        },
        {
            value: 2,
            label: "2",
        },
        {
            value: 3,
            label: "3",
        },
        {
            value: 4,
            label: "4",
        },
        {
            value: 5,
            label: "5",
        },
        {
            value: 6,
            label: "6",
        },
    ];

    const { state001 } = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState([1, 100]); // set initial value to 50
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [connectCredit, setConnectCredit] = useState(-1);
    const [showModal, setShowModal] = useState(false);

    const [selectedSkill, setSelectedSkill] = useState("");
    const [isTyping, setIsTyping] = useState(false);
    const [allskills, setAllSkills] = useState([]);
    const [skillInputSearchTerm, setSkillInputSearchTerm] = useState("");
    const [job, setJob] = useState();
    const [specializationData, setSpecializationData] = useState([]);

    const [jobData, setJobData] = useState("");
    const [orgData, setOrgData] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filled, setFilled] = useState(false);
    const [isData, setIsData] = useState(false);
    const [freelancerOption, setFreelancerOption] = useState("");

    const [duration, setDuration] = useState({
        number: "",
        durationType: [],
    });
    const [hourlyProject, setHourlyProject] = useState({
        minimum: "",
        maximum: "",
    });
    const [projectType, setProjectType] = useState("");

    const initial = {
        opportunityType: "Hourly",
        profile: filterType && filterType.startsWith("profile_") ? [filterType.split("_")[1]] : [],
        location: filterType && filterType.startsWith("location_") ? [filterType.split("_")[1]] : [],
        jobType: "",
        partTime: false,
        fullTime: false,
        skill: [],
        projectCategory: selectedCategory || selectedCategoryFromTalentMarketPlace || "",
        projectSpecialization: "",
        projectType: "",
        duration: "",
        durationType: ["month", "week"],
        experience: [],
        keyword: "",
        freelancer: "",
        minimumHourlyBudget: "",
        maximumHourlyBudget: "",
    };

    const getDurationTypeOptions = (durationNumber) => {
        const singularOptions = [
            { label: "Month", value: "month" },
            { label: "Week", value: "week" },
        ];

        const pluralOptions = [
            { label: "Months", value: "months" },
            { label: "Weeks", value: "weeks" },
        ];

        // If duration is 1, return singular options, else return plural
        return durationNumber === 1 ? singularOptions : pluralOptions;
    };

    const [filter, setFilter] = useState(initial);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    // project category and specialization filter
    useEffect(() => {
        if (job?.projectCategory !== filter.projectCategory || job?.projectSpecialization !== filter.projectSpecialization) {
            setFilter((prevFilter) => ({ ...prevFilter, projectCategory: job?.projectCategory, projectSpecialization: job?.projectSpecialization }));
        }
    }, [job, filter.projectCategory, filter.projectSpecialization]);
    useEffect(() => {
        const selectedCategory = sessionStorage.getItem("selectedCategory");
        if (selectedCategory) {
            setJob((prevJob) => ({ ...prevJob, ["projectCategory"]: selectedCategory }));
            setFilter((prev) => ({ ...prev, projectCategory: selectedCategory }));
            sessionStorage.removeItem("selectedCategory");
        }
    }, []);

    useEffect(() => {
        const selectedCategoryFromTalentMarketPlace = sessionStorage.getItem("selectedCategoryTalentMarketPlace");
        console.log("talent market place category", selectedCategoryFromTalentMarketPlace);
        if (selectedCategoryFromTalentMarketPlace) {
            setJob((prevJob) => ({ ...prevJob, ["projectCategory"]: selectedCategoryFromTalentMarketPlace }));
            setFilled((prev) => ({ ...prev, projectCategory: selectedCategoryFromTalentMarketPlace }));
            sessionStorage.removeItem("selectedCategoryTalentMarketPlace");
        }
    }, []);

    // duration and duration type filtr
    useEffect(() => {
        setFilter({
            ...filter,
            duration: duration?.number,
            durationType: duration?.durationType,
        });
    }, [duration]);

    // minimum and maximum hourly budget
    useEffect(() => {
        setFilter({
            ...filter,
            minimumHourlyBudget: hourlyProject?.minimum,
            maximumHourlyBudget: hourlyProject?.maximum,
        });
    }, [hourlyProject]);

    // freelancer filter
    useEffect(() => {
        setFilter({ ...filter, freelancer: freelancerOption?.freelancer });
    }, [freelancerOption]);

    const handleJobTypeChange = (value) => {
        setProjectType(value);
        setFilter({ ...filter, projectType: value });
    };
    // Fetch all skills
    useEffect(() => {
        async function fetchAllSkills() {
            if (skillInputSearchTerm.trim()) {
                try {
                    const response = await axios.get(`/api/data/skills`, {
                        params: { keyword: skillInputSearchTerm },
                    });
                    if (Array.isArray(response.data)) {
                        setAllSkills(response.data.map((skill) => ({ label: skill, value: skill })));
                    } else {
                        console.error("Unexpected response format:", response.data);
                    }
                } catch (error) {
                    console.error("Error fetching skills:", error);
                }
            } else {
                setAllSkills([]);
            }
        }
        fetchAllSkills();
    }, [skillInputSearchTerm]);

    // skill handler
    const handleSkillChange = (selectedOptions) => {
        setSelectedSkill(selectedOptions);

        if (selectedOptions && selectedOptions.length > 0) {
            setFilter({ ...filter, skill: selectedOptions.map((option) => option.value) });
        } else {
            setFilter({ ...filter, skill: [] });
        }
    };

    const handleSkillInputChange = (newValue) => {
        setSkillInputSearchTerm(newValue);
        setIsTyping(newValue.length > 0);
    };

    const getConnectCreditInfo = async () => {
        try {
            const response = await axios.get(`/api/employer/getConnectCreditInfomrationofEmployer/${user?._id}`);
            setConnectCredit(response.data.connectCredit);
        } catch (error) {
            console.error("Error fetching connectCredit:", error);
        }
    };

    useEffect(() => {
        if (user && user.role === "User") {
            getConnectCreditInfo();
        }
    }, [connectCredit, user]);

    useEffect(() => {
        getResumeData();
    }, []);
    // FILTER OPTION :
    const userType = localStorage.getItem("userType");

    // useEffect(() => {
    //   if (localStorage.getItem("location") && localStorage.getItem("location") != "Remote") {
    //     setFilter({ ...filter, location: [localStorage.getItem("location")] });
    //     localStorage.removeItem("location");
    //   }
    //   if (
    //     localStorage.getItem("location") &&
    //     localStorage.getItem("location") == "Remote"
    //   ) {
    //     setFilter({ ...filter, jobType: ["Remote"] });
    //     localStorage.removeItem("location");
    //   }
    //   if (localStorage.getItem("title")) {
    //     setFilter({ ...filter, profile: [localStorage.getItem("title")] });
    //     localStorage.removeItem("title");
    //   }
    // }, []);

    const [filter2, setFilter2] = useState({
        searchInput: "",
    });

    const handleFilter = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === "checkbox") {
            // If the event target is a checkbox, handle its state
            const updatedJobType = [...filter.jobType];
            if (checked) {
                updatedJobType.push(name);
            } else {
                const index = updatedJobType.indexOf(name);
                if (index > -1) {
                    updatedJobType.splice(index, 1);
                }
            }

            setFilter((prevFilter) => ({
                ...prevFilter,
                jobType: updatedJobType,
            }));
        } else {
            // For other input types, handle normally
            setFilter({
                ...filter,
                [name]: value,
            });
        }
    };

    // Fetch profile data
    const [profiles, setProfiles] = useState([]);
    useEffect(() => {
        async function getAllProfiles() {
            const response = await axios.get("/api/data/profiles");
            const data = await response.data;
            if (data) setProfiles(data);
        }

        getAllProfiles();
    }, []);

    const [search, setSearch] = useState({
        title: [],
        location: [],
    });

    const handleChange = (e) => {
        setSearch({
            ...search,
            [e.target.name]: e.target.value,
        });
    };
    const [totalItem, setTotalItem] = useState(0);

    const handleChange2 = (e) => {
        setFilter2({
            ...filter2,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearch = useCallback(
        async (abortController) => {
            try {
                setLoading(true);
                const { data } = await axios.post(
                    "/api/user/findJobs",
                    {
                        page: currentPage,
                        pageSize: 25,
                        filter: filter,
                    },
                    {
                        signal: abortController.signal,
                    }
                );

                const activeJobs = data.jobs.filter((job) => job.active === true);

                // Only update state if the request wasn't aborted
                if (!abortController.signal.aborted) {
                    setJobData(activeJobs);
                    setTotalPages(data.totalPages);
                    setTotalItem(data.totalItems);
                    setLoading(false);
                }
            } catch (error) {
                if (!abortController.signal.aborted) {
                    setLoading(false);
                    if (error.name !== "CanceledError") {
                        toast.error(error.response?.data || "An error occurred", {
                            style: { padding: "18px" },
                        });
                    }
                }
            }
        },
        [currentPage, filter]
    );
    const handleClearFilter = () => {
        window.scrollTo(0, 0);
        setFilter({ ...initial, projectType: "", keyword: "" });
        setIsSearchCalled(false);
        setIsData(false);
        setSelectedSkill("");
        setKeyword("");
        setProjectType("");
        setDuration({ number: "", durationType: [] });
        setHourlyProject({ minimum: "", maximum: "" });
        setJob({
            projectCategory: "",
            projectSpecialization: "",
        });
        setFreelancerOption({
            freelancer: "",
        });
    };
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    /*************************Search Option End********************************/

    const handleSliderChange = (newValue) => {
        // Update the rangeSalary filter based on slider values
        setFilter({
            ...filter,
            salary: newValue.target.value,
        });
    };

    const stepValues = Array.from({ length: 6 }, (_, i) => i + 2);

    const filtersBox = useRef(null);

    const toggleFiltersBox = () => {
        filtersBox.current.classList.toggle("open");
    };

    const getJobData = async () => {
        try {
            const { data } = await axios.get("/api/user/getAllJobs");
            const jobD = data.job;
            setJobData(jobD);
        } catch (error) {}
    };

    useEffect(() => {
        if (!isInitialLoad) {
            const scrollTimeout = setTimeout(() => {
                window.scrollTo(0, 0);
            }, 2000);
            return () => clearTimeout(scrollTimeout);
        }
    }, [currentPage, isInitialLoad]);
    useEffect(() => {
        if (!isInitialLoad && currentPage !== 1) {
            setCurrentPage(1);
        }
    }, [filter.profile, filter.location, filter.jobType, filter.projectCategory]);

    useEffect(() => {
        const abortController = new AbortController();

        if (isInitialLoad) {
            handleSearch(abortController);
            setIsInitialLoad(false);
        } else {
            const debounceTimer = setTimeout(() => {
                handleSearch(abortController);
            }, 300);

            return () => {
                clearTimeout(debounceTimer);
                abortController.abort();
            };
        }
    }, [filter, currentPage, handleSearch, isInitialLoad]);
    const handleSaveJob = async (jobId) => {
        try {
            const { data } = await axios.get(`/api/user/saveJob/${jobId}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });
            if (data.success) {
                setFilled(!filled);
                window.location.reload();
            }
        } catch (error) {}
    };

    useEffect(() => {
        dispatch(loadUser());
    }, []);

    const handleRemoveJob = async (jobId) => {
        try {
            const { data } = await axios.get(`/api/user/removeJob/${jobId}`, {
                headers: {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                },
            });

            if (data.success) {
                setFilled((prevFilled) => !prevFilled);
                window.location.reload();
            }
        } catch (error) {}
    };

    const [keyword, setKeyword] = useState("");

    const [isSearchCalled, setIsSearchCalled] = useState(false);

    const handleKeywordCall = (e) => {
        e.preventDefault();
        setFilter({ ...filter, keyword: keyword });
        setIsSearchCalled(false);
        setIsData(false);
    };

    const handleSearch3 = async () => {
        setLoading(true);
        setIsSearchCalled(searchValue.trim() !== "");
        try {
            const response = await axios.post("/api/user/findFilteredJobs", {
                search: {
                    profile: searchValue,
                },
                // page: currentPage, // include currentPage in the request
                page: 1, // include currentPage in the request
                pageSize: 25,
            });
            setLoading(false);
            setJobData(response.data?.data);
            setTotalPages(response.data.totalPages);
            setIsData(searchValue.trim() !== "" && response.data?.data.length > 0);
        } catch (error) {
            setLoading(false);
            console.error("Error while fetching job data:", error);
            // Handle errors
        }
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        if (!value.trim()) {
            setIsData(false); // Reset isData to false when searchValue is empty
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch3();
        }
    };

    // useEffect(() => {
    //   const delayDebounceFn = setTimeout(() => {
    //     handleSearch3();
    //   }, 300); // Adjust the delay as needed

    //   return () => clearTimeout(delayDebounceFn);
    // }, [searchValue]);
    useEffect(() => {
        if (searchValue.trim() !== "") {
            const delayDebounceFn = setTimeout(() => {
                handleSearch3();
            }, 300);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchValue]);

    const handleBuyConnects = () => {
        navigate("/freelancerbuyconnects");
        setShowModal(false);
    };

    return (
        <>
            <div className="container-fluid matched-jobs ">
                <div className="jobbox container ">
                    <div className="row RowMargin " style={{ maxWidth: "1050px" }}>
                        <div style={{ display: "flex", flexWrap: "wrap", color: "#494949", fontSize: "15px", marginLeft: "10px" }}>
                            <p style={{ marginRight: "4px", cursor: "pointer" }} onClick={() => navigate("/")}>
                                Home
                            </p>
                            <IoIosArrowForward style={{ marginRight: "4px", marginTop: "3px" }} />
                            <p style={{ marginRight: "4px", cursor: "pointer" }}>Hourly Projects</p>
                        </div>

                        <div className=" col-lg-4 col-md-12 col-sm-12 mx-auto" style={{ marginTop: "84px", width: "100%" }}>
                            <div className="filterbox  py-0 adjustfilterboxheight2" style={{ height: "83vh" }}>
                                <div className="filter-container">
                                    <div className="title">
                                        <img src={filterLogo} alt="Account" style={{ width: "25px" }} /> Filters
                                    </div>

                                    <div className="body">
                                        <div className="filter-category">
                                            <div>Keyword Search</div>

                                            <div className="body">
                                                <form onSubmit={handleKeywordCall} style={{ display: "flex" }}>
                                                    <input
                                                        minLength={3}
                                                        id="keyword"
                                                        type="text"
                                                        placeholder="Project title, Category, Specialization, Skills, Description"
                                                        value={keyword}
                                                        onChange={(e) => setKeyword(e.target.value)}
                                                        className="placeholderStyle"
                                                        style={{
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            height: "100%",
                                                            border: "1px solid #ddd",
                                                            borderRight: "none",
                                                            outline: "none",
                                                            paddingTop: "10px",
                                                            paddingBottom: "10px",
                                                            paddingLeft: "10px",
                                                            paddingRight: "10px",
                                                            borderBottomLeftRadius: "3px",
                                                            borderTopLeftRadius: "3px",
                                                            borderTopRightRadius: "0px",
                                                            borderBottomRightRadius: "0px",
                                                        }}
                                                    />
                                                    <button
                                                        type="submit"
                                                        style={{
                                                            height: "100%",
                                                            color: "white",
                                                            backgroundColor: "#00a5ec",
                                                            border: "1px solid #00a5ec",
                                                            paddingTop: "8px",
                                                            paddingBottom: "7px",
                                                            paddingLeft: "10px",
                                                            paddingRight: "10px",
                                                            borderTopRightRadius: "3px",
                                                            borderBottomRightRadius: "3px",
                                                            transition: "all 0.3s",
                                                        }}
                                                    >
                                                        <FaMagnifyingGlass />
                                                    </button>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="filter-category">
                                            <div>Project Category</div>
                                            <div style={{ width: "100%" }} className="body">
                                                <ProjectCategorySelector
                                                    setSelectedOption={(category) => {
                                                        setJob((prevJob) => ({
                                                            ...prevJob,
                                                            ["projectCategory"]: category?.value,
                                                        }));
                                                    }}
                                                    setSpecializationData={setSpecializationData}
                                                    resetSpecialization={() => {
                                                        setJob((prevJob) => ({
                                                            ...prevJob,
                                                            ["projectSpecialization"]: "",
                                                        }));
                                                    }}
                                                    selectedData={
                                                        job?.projectCategory && {
                                                            label: job?.projectCategory,
                                                            value: job?.projectCategory,
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="filter-category">
                                            <div>Project Specialization</div>
                                            <div style={{ width: "100%" }} className="body">
                                                <ProjectSpecializationSelector
                                                    setSelectedOption={(sp) => {
                                                        setJob((prevJob) => ({
                                                            ...prevJob,
                                                            ["projectSpecialization"]: sp?.value,
                                                        }));
                                                    }}
                                                    optionData={specializationData}
                                                    selectedData={
                                                        job?.projectSpecialization && {
                                                            label: job?.projectSpecialization,
                                                            value: job?.projectSpecialization,
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="filter-category">
                                            <div>Skills</div>
                                            <div
                                                className="body"
                                                style={{
                                                    border: "1px solid #ddd",
                                                    borderRadius: "3px",
                                                    padding: "3px 0px",
                                                }}
                                                onMouseEnter={(e) => (e.currentTarget.style.border = "1px solid blue")}
                                                onMouseLeave={(e) => (e.currentTarget.style.border = "1px solid #ddd")}
                                            >
                                                <Select placeholder="e.g. Python" className="select-tag" isMulti value={selectedSkill} onChange={handleSkillChange} options={allskills} onInputChange={handleSkillInputChange} styles={customStyles} menuIsOpen={isTyping} />
                                            </div>
                                        </div>

                                        <div className="filter-category">
                                            <div> Project Type </div>
                                            <div className="inner-filter-container" style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <div className="inner-filter-category">
                                                    <input type="radio" name="jobType" id="inOffice" onChange={(e) => handleJobTypeChange(e.target.value)} value="Office" checked={projectType === "Office"} />
                                                    <label htmlFor="inOffice" style={{ fontSize: "14px" }}>
                                                        In Office
                                                    </label>
                                                </div>
                                                <div className="inner-filter-category">
                                                    <input type="radio" name="jobType" id="remote" onChange={(e) => handleJobTypeChange(e.target.value)} value="Remote" checked={projectType === "Remote"} />
                                                    <label htmlFor="remote" style={{ fontSize: "14px" }}>
                                                        Remote
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="filter-category checkbox-container ">
                                            <div>Part-time / Full-time</div>
                                            <div className="checkBoxInnerContainer" style={{ display: "flex" }}>
                                                <div className="category d-flex align-items-center gap-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={filter.partTime}
                                                        onChange={() => {
                                                            setFilter({
                                                                ...filter,
                                                                partTime: !filter.partTime,
                                                            });
                                                            setIsSearchCalled(false);
                                                            setIsData(false);
                                                        }}
                                                    />
                                                    <span style={{ fontSize: "14px" }}>Part-time</span>
                                                </div>
                                                <div className="category d-flex align-items-center gap-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={filter.fullTime}
                                                        onChange={() => {
                                                            setFilter({
                                                                ...filter,
                                                                fullTime: !filter.fullTime,
                                                            });
                                                            setIsSearchCalled(false);
                                                            setIsData(false);
                                                        }}
                                                    />
                                                    <span style={{ fontSize: "14px" }}>Full-time</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="filter-category">
                                            <div>Number of freelancers needed</div>
                                            <div style={{ width: "100%" }} className="body">
                                                <FreelancerDropDown
                                                    setSelectedOption={(sp) => {
                                                        setFreelancerOption((prev) => ({
                                                            ...prev,
                                                            ["freelancer"]: sp?.value,
                                                        }));
                                                    }}
                                                    optionData={numberOfFreelancerNeededData}
                                                    selectedData={
                                                        freelancerOption?.freelancer && {
                                                            label: freelancerOption?.freelancer,
                                                            value: freelancerOption?.freelancer,
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="filter-category">
                                            <div>Project Duration</div>
                                            <div className="inner-filter-container" style={{ display: "flex", alignItems: "center" }}>
                                                <div className="inner-filter-duration">
                                                    <DurationDropDown
                                                        setSelectedOption={(sp) => {
                                                            setDuration((prev) => ({ ...prev, ["number"]: sp?.value }));
                                                        }}
                                                        optionData={durationData}
                                                        selectedData={
                                                            duration?.number && {
                                                                label: duration?.number,
                                                                value: duration?.number,
                                                            }
                                                        }
                                                    />
                                                </div>
                                                <div className="inner-filter-durationType">
                                                    {/* <div style={{ opacity: 0, pointerEvents: "none" }}>Type</div> */}
                                                    <DurationTypeDropDown
                                                        setSelectedOption={(sp) => {
                                                            setDuration((prev) => ({
                                                                ...prev,
                                                                durationType: sp ? [sp.value] : [],
                                                            }));
                                                        }}
                                                        optionData={getDurationTypeOptions(duration?.number)}
                                                        selectedData={
                                                            duration?.durationType?.[0] ? { label: duration.durationType[0], value: duration.durationType[0] } : null // Set to null when durationType is empty
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="filter-category">
                                            <div>Location</div>
                                            <div className="body">
                                                <Dropdown multiple={true} placeholder="e.g. Delhi" category="location" actual={filter} setActual={setFilter} setIsSearchCalled={setIsSearchCalled} setIsData={setIsData} />
                                            </div>
                                        </div>
                                        <div className="filter-category">
                                            <div>Hourly Budget</div>

                                            <div className="inner-filter-container" style={{ display: "flex", alignItems: "center" }}>
                                                <div className="inner-filter-minimum">
                                                    <input
                                                        id="minimum-hourly-budget"
                                                        type="number"
                                                        placeholder="Minimum"
                                                        value={hourlyProject?.minimum}
                                                        onChange={(e) =>
                                                            setHourlyProject((prevState) => ({
                                                                ...prevState,
                                                                minimum: e.target.value,
                                                            }))
                                                        }
                                                        className="placeholderStyle"
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "100%",
                                                            width: "100%",
                                                            border: "1px solid #ddd",
                                                            outline: "none",
                                                            paddingTop: "10px",
                                                            paddingBottom: "10px",
                                                            paddingLeft: "10px",
                                                            paddingRight: "10px",
                                                            borderBottomLeftRadius: "3px",
                                                            borderTopLeftRadius: "3px",
                                                            borderTopRightRadius: "3px",
                                                            borderBottomRightRadius: "3px",
                                                        }}
                                                    />
                                                </div>
                                                <div className="inner-filter-maximum">
                                                    <input
                                                        id="maximum-hourly-budget"
                                                        type="number"
                                                        placeholder="Maximum"
                                                        value={hourlyProject?.maximum}
                                                        onChange={(e) =>
                                                            setHourlyProject((prevState) => ({
                                                                ...prevState,
                                                                maximum: e.target.value,
                                                            }))
                                                        }
                                                        className="placeholderStyle"
                                                        style={{
                                                            fontSize: "16px",
                                                            height: "100%",
                                                            border: "1px solid #ddd",
                                                            outline: "none",
                                                            paddingTop: "10px",
                                                            paddingBottom: "10px",
                                                            paddingLeft: "10px",
                                                            paddingRight: "10px",
                                                            borderBottomLeftRadius: "3px",
                                                            borderTopLeftRadius: "3px",
                                                            borderTopRightRadius: "3px",
                                                            borderBottomRightRadius: "3px",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <span onClick={handleClearFilter} className="ml-auto instruction mt-[10px]">
                                            Clear all
                                        </span>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        justifyItems: "center",
                                        marginTop: "-15px",
                                    }}
                                ></div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 col-sm-12" style={{ width: "100%" }}>
                            <div className="fixed-header">
                                <h3 style={{ cursor: "pointer" }} onClick={handleTitle} className="title my-5">
                                    {loading ? "" : isSearchCalled ? jobData?.length : totalItem} {filter.jobType ? " Work From Home" : ""} {isData ? jobData[0]?.profile : filter.profile ? filter.profile : ""} Hourly Projects {filter.location && filter.location.length > 0 ? " in " + filter.location : ""} {heading ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "}
                                </h3>
                                {heading ? (
                                    <>
                                        <p
                                            style={{
                                                marginTop: "-20px",
                                                fontSize: "13px",
                                                color: "#494949",
                                                textAlign: "center",
                                            }}
                                        >
                                            Recent Hourly Cost projects and openings across the globe
                                        </p>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </div>

                            {loading ? (
                                <>
                                    <div className="my-auto" style={{ textAlign: "center", marginLeft: "-10px" }}>
                                        <p
                                            style={{
                                                color: "#4B92C8",
                                                fontWeight: "500",
                                                marginTop: "170px",
                                                fontSize: "20px",
                                                marginLeft: "17px",
                                                marginBottom: "5px",
                                            }}
                                        >
                                            Loading..
                                        </p>
                                        <PropagateLoader color={"#4B92C8"} loading={true} size={15} aria-label="Loading Spinner" data-testid="loader" />
                                    </div>
                                </>
                            ) : (
                                <>
                                    {jobData && jobData.length != 0 ? (
                                        <>
                                            <div className="content-below-header">
                                                <div className="alljobdetails pb-2">
                                                    {jobData?.map((data, index) => {
                                                        return (
                                                            <>
                                                                <div className="cards my-4" key={data?.profile}>
                                                                    <div className="card py-4 px-5 ">
                                                                        <div className="cardHeader">
                                                                            <div
                                                                                className="mt-3  wfm123"
                                                                                style={{
                                                                                    width: "124px",
                                                                                    borderRadius: "5px",
                                                                                    fontSize: "13px",
                                                                                    textAlign: "center",
                                                                                    fontWeight: "400",
                                                                                    padding: "3px",
                                                                                    color: "black",
                                                                                    border: "solid 1px #D1D1D1",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={arrowup}
                                                                                    alt=""
                                                                                    style={{
                                                                                        marginRight: "5px",
                                                                                        height: "17px",
                                                                                        marginTop: "-4px",
                                                                                    }}
                                                                                />
                                                                                Actively hiring
                                                                            </div>
                                                                            <div className="row">
                                                                                <div style={{ width: "100%", }} >
                                                                                    <a style={{textDecoration: "none",color: "black",}} onClick={() => handleAddEvent("view project details")} target="_blank" href={`/preview/${data?.profile?.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data?.opportunityType}-at-${data?.employer?.organisationName?.replace(/ /g, "-")}_${data._id}`.toLowerCase()}
                                                                                    >
                                                                                        <TruncateText text={data?.profile} maxWords={20} />
                                                                                    </a>
                                                                                </div>
                                                                            </div>

                                                                            <div className="content mb-5">
                                                                                <div
                                                                                    className=" pt-4 pb-5 "
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flexWrap: "wrap",
                                                                                        gap: "20px 70px",
                                                                                    }}
                                                                                >
                                                                                    <div className="statusBoxes">
                                                                                        <p>
                                                                                            <span>
                                                                                                <img
                                                                                                    src={circle}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: "16px",
                                                                                                    }}
                                                                                                />
                                                                                            </span>
                                                                                            <span
                                                                                                className="mx-2 heeading"
                                                                                                style={{
                                                                                                    fontWeight: "500",
                                                                                                }}
                                                                                            >
                                                                                                START DATE
                                                                                            </span>
                                                                                        </p>
                                                                                        <h3
                                                                                            className="wfm123"
                                                                                            style={{
                                                                                                fontSize: "14px",
                                                                                                maxWidth: "100px",
                                                                                            }}
                                                                                        >
                                                                                            {data.startDate == "Immediately (within next 30 days)" ? <>Immediately</> : <>{data.startDate}</>}
                                                                                        </h3>
                                                                                    </div>

                                                                                    <div className="statusBoxes">
                                                                                        <p>
                                                                                            <span>
                                                                                                <img
                                                                                                    src={calendar}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: "16px",
                                                                                                    }}
                                                                                                />
                                                                                            </span>
                                                                                            <span
                                                                                                className="mx-2 heeading"
                                                                                                style={{
                                                                                                    fontWeight: "500",
                                                                                                }}
                                                                                            >
                                                                                                DURATION
                                                                                            </span>
                                                                                        </p>
                                                                                        <h3
                                                                                            className="wfm123"
                                                                                            style={{
                                                                                                fontSize: "14px",
                                                                                            }}
                                                                                        >
                                                                                            {data.duration} {data?.durationType || "months"}
                                                                                        </h3>
                                                                                    </div>

                                                                                    <div className="statusBoxes">
                                                                                        <p>
                                                                                            <span>
                                                                                                <img
                                                                                                    src={money}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        width: "16px",
                                                                                                    }}
                                                                                                />
                                                                                            </span>
                                                                                            <span
                                                                                                className="mx-2 heeading"
                                                                                                style={{
                                                                                                    fontWeight: "500",
                                                                                                }}
                                                                                            >
                                                                                                BUDGET
                                                                                            </span>
                                                                                        </p>
                                                                                        {!data.salary && data.salary == 0 ? (
                                                                                            <>
                                                                                                <h3
                                                                                                    className="wfm123"
                                                                                                    style={{
                                                                                                        fontSize: "14px",
                                                                                                    }}
                                                                                                >
                                                                                                    Unpaid
                                                                                                </h3>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <h3
                                                                                                    className="wfm123"
                                                                                                    style={{
                                                                                                        fontSize: "14px",
                                                                                                    }}
                                                                                                >
                                                                                                    ${data.salary}
                                                                                                    {data.salaryMax ? <> - ${data.salaryMax} per hour</> : <></>}
                                                                                                </h3>
                                                                                            </>
                                                                                        )}
                                                                                    </div>

                                                                                    <div className="pr-1 pb-4 ">
                                                                                        <span style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                                                                                            <span
                                                                                                className=" post1"
                                                                                                style={{
                                                                                                    backgroundColor: determineBgColor(data.datePosted),
                                                                                                    color: determineColor(data.datePosted),
                                                                                                }}
                                                                                            >
                                                                                                <RxCounterClockwiseClock
                                                                                                    style={{
                                                                                                        fontSize: "14px",
                                                                                                        marginTop: "-2px",
                                                                                                    }}
                                                                                                />{" "}
                                                                                                Posted {timeAgo(data.datePosted)}
                                                                                            </span>
                                                                                            <span className=" post1">{data.opportunityType}</span>

                                                                                            <span className=" post1">{data?.projectCategory}</span>
                                                                                            <span className=" post1">{data?.projectSpecialization}</span>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr
                                                                                className=""
                                                                                style={{
                                                                                    height: "0.1px",
                                                                                    color: "#C4C4C4",
                                                                                    marginTop: "-60px",
                                                                                }}
                                                                            ></hr>
                                                                            <div className="cardbutton ">
                                                                                <div
                                                                                    className="ml-auto text-end"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        flexWrap: "wrap",
                                                                                    }}
                                                                                >
                                                                                    <a
                                                                                        href={`/preview/${data?.profile?.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data?.opportunityType}-at-${data?.employer?.organisationName?.replace(/ /g, "-")}_${data._id}`.toLowerCase()}
                                                                                        rel="noreferrer"
                                                                                        target="_blank"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            const url = openPreviewPageInNewTab({ data });
                                                                                            if (url) {
                                                                                                window.open(url, "_blank", "noopener,noreferrer");
                                                                                            }
                                                                                            handleAddEvent("view details")
                                                                                        }}
                                                                                        className="btnview1 ml-auto"
                                                                                        style={{
                                                                                            textDecoration: "none",
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                        }}
                                                                                    >
                                                                                        View Details
                                                                                    </a>

                                                                                    {user ? (
                                                                                        userType !== "Employer" &&
                                                                                        userType !== null &&
                                                                                        (data.userApplied.includes(user ? user._id : "") ? (
                                                                                            <>
                                                                                                <p
                                                                                                    style={{
                                                                                                        color: "green",
                                                                                                        fontWeight: "400",
                                                                                                        marginBottom: "-2px",
                                                                                                        marginLeft: "10px",
                                                                                                        marginRight: "10px",
                                                                                                    }}
                                                                                                >
                                                                                                    <MdOutlineCheckCircle
                                                                                                        style={{
                                                                                                            marginRight: "3px",
                                                                                                            fontSize: "18px",
                                                                                                            marginTop: "-2px",
                                                                                                        }}
                                                                                                    />
                                                                                                    Applied
                                                                                                </p>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <ApplyButton data={data} user={user} profiledetail={profiledetail} connectCredit={connectCredit} setShowModal={setShowModal} />
                                                                                            </>
                                                                                        ))
                                                                                    ) : (
                                                                                        <>
                                                                                            {/* <ApplyButton data={data} user={user} profiledetail={profiledetail} connectCredit={connectCredit} setShowModal={setShowModal} /> */}

                                                                                            <div style={{ textAlign: "center" }}>
                                                                                                <a
                                                                                                    href="#"
                                                                                                    onClick={(e) => {``
                                                                                                        e.preventDefault();
                                                                                                        const currentPath = location.pathname;
                                                                                                        logEvent("Button", `Clicked apply now button`, user ? user.role : "Guest");

                                                                                                        // Store any necessary job data
                                                                                                        if (data) {
                                                                                                            // assuming 'data' is your job data
                                                                                                            localStorage.setItem("jobApplyData", JSON.stringify(data));
                                                                                                        }

                                                                                                        // Navigate to login with the return path
                                                                                                        navigate("/login", {
                                                                                                            state: {
                                                                                                                from: currentPath,
                                                                                                            },
                                                                                                        });
                                                                                                    }}
                                                                                                    className="btn btnapply"
                                                                                                    style={{
                                                                                                        marginLeft: "0px",
                                                                                                    }}
                                                                                                >
                                                                                                    Apply Now
                                                                                                </a>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-center mt-5">
                                                <i className="fa fa-window-close-o mx-auto" aria-hidden="true" style={{ fontSize: "200px" }}></i>
                                                <h1>Projects not Found</h1>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="pagination" style={{ width: "100%", maxWidth: "1200px", margin: "0px" }}>
                            {totalPages > 1 ? (
                                <>
                                    <div style={{ textAlign: "center", maxWidth: "1200px" }}>
                                        <PaginationWithTooltip totalPages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} />
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Popup open={showModal} closeOnDocumentClick onClose={() => setShowModal(false)}>
                <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
                    <div className="popup-containernew">
                        <div className="close-button12345" onClick={() => setShowModal(false)}>
                            <RxCross1 />
                        </div>
                        <div className="success-message mx-3">Your Connect credit limit has been reached.</div>
                        <div className="success-message1 mx-3">To continue applying, kindly purchase more Connects.</div>
                        <div className="d-flex flex-column flex-md-row gap-5" style={{ marginTop: "10px", marginBottom: "20px" }}>
                            <button type="button" className="submit-btn12345" onClick={handleBuyConnects}>
                                Buy Connects
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
            <ToastContainer position="top-center" autoClose={10000} />
        </>
    );
}

export default Jobs;
